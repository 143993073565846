import React, { Component } from "react";
import Slider from "react-slick";

import galery1 from "./../../images/2020/exterior1_2020.jpeg";
import galery2 from "./../../images/2021/exterior3.jpeg";
import galery3 from "./../../images/2020/2020_exterior4.jpeg";
// import  galery4 from './../../images/gallery/gallery-6/pic4.jpg';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
    </div>
  );
}

class ExhibitionSlider1 extends Component {
  render() {
    var settings = {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <Slider className="exhibition-carousel owl-carousel owl-none m-b30 " {...settings}>
        <div className="item">
          <img
            className="w-100"
            src={galery3}
            alt=""
            style={{ width: "520px", maxHeight: "570px", objectFit: "contain" }}
          />
        </div>
        <div className="item">
          <img
            className="w-100"
            src={galery1}
            alt=""
            style={{ width: "520px", maxHeight: "570px", objectFit: "contain" }}
          />
        </div>
        <div className="item">
          <img
            className="w-100"
            src={galery2}
            alt=""
            style={{ width: "520px", maxHeight: "570px", objectFit: "contain" }}
          />
        </div>
        {/* <div className="item">
          <img className="w-100" src={galery4} alt="" />
        </div> */}
      </Slider>
    );
  }
}

export default ExhibitionSlider1;
