import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import logo from "./../../images/logo/logo-rosu-maro.png";

class Header extends Component {
	componentDidMount() {
		// sidebar open/close

		// var Navicon = document.querySelector(".navicon");
		var sidebarmenu = document.querySelector(".myNavbar ");

		function toggleFunc() {
			sidebarmenu.classList.toggle("show");
			// Navicon.classList.toggle("open");
		}
		// Navicon.addEventListener("click", toggleFunc);

		// Sidenav li open close
		var navUl = [].slice.call(document.querySelectorAll(".navbar-nav > li"));
		for (var y = 0; y < navUl.length; y++) {
			navUl[y].addEventListener("click", function () {
				checkLi(this);
			});
		}

		function checkLi(current) {
			navUl.forEach((el) => (current !== el ? el.classList.remove("open") : ""));

			setTimeout(() => {
				current.classList.toggle("open");

				/* alert(current.className.indexOf('open'));
				if(current.className.indexOf('open') == -1){
					current.classList.toggle('open');
					alert(2);
				}else{
				alert(current.parentNode);
					current.parentNode.classList.add('khelesh');
				} */
			}, 100);
		}
	}
	render() {
		return (
			<Fragment>
				{/*  header  */}
				<header className="site-header header-transparent">
					<HeaderContent />
				</header>
				{/*  header End  */}
			</Fragment>
		);
	}
}
function HeaderContent() {
	return (
		<>
			<div className="top-bar">
				<div className="container-fluid">
					<div className="row d-flex justify-content-md-between justify-content-center align-items-center">
						<div className="dlab-topbar-left">
							<ul>
								<li>
									<i className="la la-phone-volume"></i> 0723621665 / 0752060734
								</li>
								<li>
									<i className="las la-map-marker"></i> BRADU, Strada Principala nr. 378
								</li>
							</ul>
						</div>
						<div className="dlab-topbar-right">
							<ul>
								<li>
									<i className="la la-clock"></i> Luni - Vineri: 8:00 - 18.00
								</li>
								<li>
									<i className="las la-envelope-open"></i> mlctimberframe@gmail.com
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/*   main header  */}
			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container-fluid clearfix">
						{/* website logo */}

						{/* nav toggle button  */}
						{/* <button
              className="navbar-toggler collapsed navicon justify-content-end "
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button> */}
						{/*extra nav  */}
						{/* <div className="extra-nav">
              <div className="extra-cell">
                <Link to={"/contact"} className="btn btn-primary btnhover13">
                  Contact
                </Link>
              </div>
            </div> */}
						<div className="header-nav justify-content-center">
							<ul className="nav navbar">
								<li>
									<Link to={"#"}>
										Portofoliu<i className="fa fa-chevron-down"></i>
									</Link>
									<ul className="sub-menu">
										<li>
											<Link to={"/proiect-2019"}>Proiect 2019</Link>
										</li>
										<li>
											<Link to={"/proiect-2020"}>Proiect 2020</Link>
										</li>
										<li>
											<Link to={"/proiect-2021"}>Proiect 2021</Link>
										</li>
										<li>
											<Link to={"/proiect-2022"}>Proiect 2022</Link>
										</li>
										<li>
											<Link to={"/proiect-2023"}>Proiect 2023</Link>
										</li>
									</ul>
								</li>
								<li className="active about-us-nav-item">
									<Link to={"/about-us"}>
										Despre noi
										{/* <i className="fa fa-chevron-down"></i> */}
									</Link>
								</li>
								<li>
									<div className="extra-nav">
										<div className="extra-cell">
											<Link to={"/contact"} className="btn btn-primary btnhover13">
												Contact
											</Link>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export { HeaderContent };
export default Header;
