import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Header from "./../Layout/Header";
import Footer2 from "./../Layout/Footer2";
import PageTitle from "./../Layout/PageTitle";
import ExhibitionSlider1 from "./../Element/ExhibitionSlider1";
import ExhibitionSlider2 from "./../Element/ExhibitionSlider2";

//images/

class CompanyExhibition extends Component {
	componentDidMount() {
		new WOW.WOW().init();
	}
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					<PageTitle motherMenu="Despre Noi" activeMenu="Despre noi" />
					{/*  Inner Page Banner */}
					<section className="content-inner exhibition-bx" data-content="DESPRE NOI">
						<ExhibitionSection />
					</section>
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}

function ExhibitionSection() {
	return (
		<>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 ">
						<ExhibitionSlider1 />
					</div>
					<div className="col-lg-6 m-b30">
						<div className="section-head">
							<h3>MISIUNEA COMPANIEI MLC RESIDENCE</h3>
							<span>
								- Calitate
								<br />
								- Rapiditate
								<br />
								- Seriozitate
								<br />- Siguranta
							</span>
						</div>

						<div className="section-head">
							<h3>VIZIUNEA COMPANIEI MLC</h3>
							<span>
								- Crearea unei clientele fidele
								<br />
								- Cresterea permanenta a calitatii serviciilor
								<br />
								- Parteneriate de succes
								<br />- Incredere
							</span>
						</div>

						<div className="section-head">
							<h3>VALORILE COMPANIEI MLC</h3>
							<span>
								- Creativitate si imaginatie
								<br />
								- Imbunatatirea continua
								<br />
								- Curaj pentru noi provocari
								<br />
							</span>
						</div>

						<div className="section-head">
							<h3>
								CE NE REPREZINTA? <span className="text-primary">SIGURANTA</span>
							</h3>
							<span>
								Siguranta este principala si cea mai importanta parte din acest domeniu fara de care dezvoltarea nu ar
								exista.
							</span>
						</div>

						<div className="section-head">
							<h3>PROMPTITUDINE, DURABILITATE si INTEGRITATE</h3>
							<span>
								Putem spune ca munca depusa este clar vizibila si laudabila, lucru ce ne ofera integritate si onestitate
								in acest domeniu.
							</span>
						</div>
						<Link to={"/contact"} className="btn btn-primary m-r10 m-b10">
							Contact
						</Link>
						{/* <Link to={"/portfolio-1"} className="btn btn-primary m-b10">
              Portfoliou
            </Link> */}
					</div>
				</div>
			</div>
		</>
	);
}
export { ExhibitionSection };

export default CompanyExhibition;
