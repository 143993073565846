import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { Accordion, Card } from "react-bootstrap";

class SpecificationsBlog extends Component {
  componentDidMount() {
    new WOW.WOW().init();

    //SPECIFICATIONS Left Side hover
    var faqCardHeader = document.querySelectorAll(".faqSpecifications .card-header a");
    var faqMedia = document.querySelectorAll(".faq-media img");

    var fch = [].slice.call(faqCardHeader);
    var fcMedia = [].slice.call(faqMedia);

    for (var y = 0; y < fch.length; y++) {
      fch[y].addEventListener("mouseenter", function () {
        galleryActive(this);
      });
    }

    function galleryActive(current) {
      fcMedia.forEach((el) => el.classList.remove("active"));

      setTimeout(() => {
        var dataImageBx = current.getAttribute("data-image-bx");
        document.querySelector("#" + dataImageBx).classList.add("active");
      }, 100);
    }
  }
  render() {
    return (
      <Fragment>
        <div className="col-md-4 col-lg-4 col-xl-3 col-xxxl-2 faq-list">
          {/* <div className="text-right mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
						<Link to={"./blog-grid"} className="btn btn-primary">View All Services</Link>
					</div> */}
          <Accordion defaultActiveKey="0" className="faqSpecifications">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="0" data-image-bx="structura">
                Structura
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0" className="collapse faq-content">
                <Card.Body>proiectele noastre sunt realizate din cadre de beton, stâlpi și centuri cu beton de clasa superioara de la furnizori de renume - HIDRO ARGEȘ, VSC BETOANE sau TGM GROUP.</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="1" data-image-bx="fundatia">
                FUNDAȚIA
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1" className="collapse faq-content">
                <Card.Body>- 1,5 metri adâncime de la cota zero<br />
- fier conform normativelor și proiectului de rezistenta <br />
- beton clasă superioară - B 250 ( HIDRO- ARGEȘ) <br />
- structura locuințelor fiind realizata pe cadre de beton cu stalpi de rezistenta și cărămidă Porotherm 25 Robust.</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="2" data-image-bx="finisaje-exterioare">
                FINISAJE EXTERIOARE 
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2" className="collapse faq-content">
                <Card.Body>- Termoizolatie cu polistiren-AUSTROTERM de 100 MM, plasa pentru polistiren de 160 grame ( plasa de exterior portocalie), adezivi de foarte bună calitate, pentru lipire și masa șpaclu<br /> 
- tencuiala decorativa APLA TENCO PASTEL CU SILICON<br />
- soclu de beton placat cu polistiren <br />
- trepte și balcon placate cu gresie portelanata de exterior, adeziv super elastic, pentru lipire de cea mai buna calitate, respectiv MAPEI ADESILEX P9</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="3" data-image-bx="tamplarie">
                TÂMPLĂRIE 
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3" className="collapse faq-content">
                <Card.Body>- tamplarie PVC - gri antracit, feronerie ROTO</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="4" data-image-bx="interior">
                INTERIOR 
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4" className="collapse faq-content">
                <Card.Body>
                  Casele sunt abordate cu șape de beton atât la parter cât și la etaj ( sub placarea cu parchet).<br />
                  RIGIPS la interior, structura metalica, finisat cu produse de calitate, respectiv CERESIT, SAINT GOBAIN, ATLAS.<br />
                  Tavanele, pentru o izolație de excepție, sunt abordate cu un strat de 10 cm de vata minerala. <br />
                  Lavabila APLA LUX de interior <br />
                  - Incalzire cu centrală proprie( ARISTON) + radiatoare <br />
                  - uși de interior, parchet, gresie, obiecte sanitare și aparataje electrice ( contor, siguranțe automate, rame, prize și întrerupătoare GEWISS ), spoturi. 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="5" data-image-bx="invelitori">
                ÎNVELITORI
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5" className="collapse faq-content">
                <Card.Body>- acoperisul este realizat din tabla faltuita gri antracit , marca BILKA, </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="7" data-image-bx="imprejmuire">
                ÎMPREJMUIRE 
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7" className="collapse faq-content">
                <Card.Body>Fiecare imobil este imprejmuit cu gard, cu soclu de beton și  lemn finisat și vopsit, pe trei laturi ( sau doua laturi cu lemn +spatele cu panou bordurat - după caz), iar în față, gard de beton, finisat cu tencuiala decorativa și porți din profile metalice.</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Fragment>
    );
  }
}

export default SpecificationsBlog;
