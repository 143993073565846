import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle from "./../../Layout/PageTitle";
import Mapview from "./../../Element/Mapview";

//images/

import o1 from "./../../../images/office/office_1.jpeg";
import o2 from "./../../../images/office/office_2.jpeg";
import o3 from "./../../../images/office/office_3.jpeg";
import o4 from "./../../../images/office/office_4.jpeg";
import o5 from "./../../../images/office/office_5.jpeg";
import o6 from "./../../../images/office/office_6.jpeg";
import o_outside from "./../../../images/office/office_outside.jpeg";

import bgimg from "./../../../images/bg-view.png";

class ContactUs1 extends Component {
	componentDidMount() {
		new WOW.WOW().init();
	}
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					<PageTitle motherMenu="Contact" activeMenu="Contact" />
					{/*  Inner Page Banner */}
					<section className="p-t90" data-content="CONTACT">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-4 col-md-5 col-sm-12">
									<div className="section-head m-b30">
										<h2 className="title">Ai vreo intrebare?</h2>
										<div className="dlab-separator bg-primary"></div>
										<h5 className="title-small">CONTACTEAZA-NE</h5>
									</div>
									<ul className="contact-question">
										<li>
											<i className="fa fa-map-marker"></i>
											<h4 className="title">Adresa</h4>
											<p>BRADU, Strada Principala nr. 378</p>
										</li>
										<li>
											<i className="fa fa-envelope-o"></i>
											<h4 className="title">Email</h4>
											<p>mlctimberframe@gmail.com</p>
										</li>
										<li>
											<i className="fa fa-phone"></i>
											<h4 className="title">Telefon</h4>
											<p>0752060734</p>
											<p>0723621665</p>
										</li>
									</ul>
								</div>
								<div className="col-lg-8 col-md-7 col-sm-12 m-b30">
									<form className="contact-box dzForm p-a30 border-1" action="https://submit-form.com/qagJ4OqH">
										<h3 className="title-box">
											Vrei sa iei legatura cu unul dintre consultantii nostri sau ai intrebari despre vreun proiect?
										</h3>
										<div className="dzFormMsg m-b20"></div>
										<input type="hidden" value="Contact" name="dzToDo" />
										<div className="row">
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="prenume" type="text" required className="form-control" placeholder="Prenume" />
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="nume" type="text" required className="form-control" placeholder="Nume" />
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" required className="form-control" placeholder="Telefon" />
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="email" type="email" className="form-control" required placeholder="Email" />
													</div>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<div className="input-group">
														<textarea
															name="mesaj"
															rows="4"
															className="form-control"
															required
															placeholder="Mesajul tau"
														></textarea>
													</div>
												</div>
											</div>
											{/* <div className="col-lg-12">
                        <div className="form-group form-recaptcha">
                          <div className="input-group">
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                              data-callback="verifyRecaptchaCallback"
                              data-expired-callback="expiredRecaptchaCallback"
                            ></div>
                            <input
                              className="form-control d-none"
                              style={{ display: "none" }}
                              data-recaptcha="true"
                              required
                              data-error="Please complete the Captcha"
                            />
                          </div>
                        </div>
                      </div> */}
											<div className="col-lg-12 col-md-12">
												<button type="submit" value="Submit" className="btn btn-primary btn-lg">
													Trimite
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>

					<section className="content-inner exhibition-bx">
						<div className="container">
							<div className="section-head m-b30">
								<h2 className="title">BIROU RELATII CLIENTI</h2>

								<div className="card-columns">
									<div className="m-b30">
										<img src={o_outside} alt="" />
									</div>

									<div className="m-b30">
										<img src={o1} alt="" />
									</div>

									<div className="m-b30">
										<img src={o2} alt="" />
									</div>

									<div className="m-b30">
										<img src={o3} alt="" />
									</div>

									<div className="m-b30">
										<img src={o4} alt="" />
									</div>
									{/* 
									<div className="m-b30">
										<img src={o5} alt="" />
									</div> */}

									<div className="m-b30">
										<img src={o6} alt="" />
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* <div>
            <img src={bgimg} className="bg-view" alt="" />
          </div> */}
					{/* <Mapview /> */}
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}
export default ContactUs1;
