import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "../../Layout/Header";
import Footer2 from "../../Layout/Footer2";
import PageTitle from "../../Layout/PageTitle";
import { VideoPopup2 } from "../../Element/VideoPopup";

import img2 from "./../../../images/projects/img2.jpg";

import gal1 from "./../../../images/2019/2019-1.jpeg";
import gal2 from "./../../../images/2019/2019-2.jpeg";
import gal3 from "./../../../images/2019/2019-3.jpeg";
import gal4 from "./../../../images/2019/2019-4.jpeg";
import gal5 from "./../../../images/2019/2019-5.jpeg";
import gal6 from "./../../../images/2019/2019-6.jpeg";
import gal7 from "./../../../images/2019/2019-7.jpeg";
import plan1 from "./../../../images/2019/2019-plan-1.jpeg";
import plan2 from "./../../../images/2019/2019-plan-2.jpeg";

import gal9 from "./../../../images/2019/2019-constructie-1.jpeg";
import gal10 from "./../../../images/2019/2019-interior-1.jpeg";

import g2019_1 from "./../../../images/2019/g2019-1.jpeg";
import g2019_2 from "./../../../images/2019/g2019-2.jpeg";
import g2019_3 from "./../../../images/2019/g2019-3.jpeg";
import g2019_4 from "./../../../images/2019/g2019-4.jpeg";

import gal11 from "./../../../images/2019/2019_exteriorFinalizat1.jpeg";
import gal12 from "./../../../images/2019/exteriorFinalizat2_2019.jpeg";
import gal13 from "./../../../images/2019/exteriorFinalizat3_2019.jpeg";
import gal14 from "./../../../images/2019/exteriorFinalizat4_2019.jpeg";
import SitePlanTab from "../Homepage/SitePlanTab";
import SwiperSlider from "../Aboutus/SwiperSlider";

//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();

  return (
    <Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="lightimg ">
      <i className="ti-zoom-in icon-bx-xs"></i>
    </Link>
  );
};

const detailBlog = [
  { icon: <i className="ti ti-location-pin" />, title: "Locatie", subtitle: "Budeasa" },
  { icon: <i className="ti ti-ruler-alt-2" />, title: "Mărime proiect", subtitle: "3650 mp" },
  // { icon: <i className="ti ti-home" />, title: "Tip confort", subtitle: "PROIECT REZIDENȚIAL DE LOCUINȚE DECOMANDATE" },
];

const projectPlans = [{ image: plan1 }, { image: plan2 }];

const OverlayBlog = ({ imageType, index }) => {
  return (
    <>
      <div className="dlab-box gallery-box-2">
        <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
          <img src={imageType} alt="" />
          <div className="overlay-bx">
            <Iconimage imageToOpen={index} />
          </div>
        </div>
      </div>
    </>
  );
};

class Project2019 extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white" id="lightgallery">
          {/*  banner  */}
          <PageTitle motherMenu="Proiect 2019" activeMenu="Proiect 2019" />
          {/*  Project Img  */}
          <SimpleReactLightbox>
            <SRLWrapper>
              {/* <div className="section-full content-inner-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mfp-gallery">
                      <OverlayBlog imageType={img1} />
                    </div>
                  </div>
                </div>
              </div> */}
              {/*  Project Img End */}
              {/* Project Info  */}

              <section className="section-full content-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 m-b30">
                      <div className="section-head mb-3">
                        <p>2019 - FINALIZAT</p>
                        <h4 className="title">PROIECT REZIDENȚIAL DE LOCUINȚE DECOMANDATE - TIP CONFORT</h4>
                      </div>
                      <p>Compus din 9 locuințe individuale.</p>
                      <p className="m-b30">
                        <b>ARHITECTURA:</b> Arhitectura proiectului nostru a fost concepută gândindu-ne la tine și la
                        cum esti tu! Din acest motiv, elementele moderne povestesc mult despre nevoile tale și despre
                        identitatea ta.Am construit atent la detalii gândindu-ne cum ți-ai dori să îți petreci
                        diminețile în grădină, cum să beneficiezi de o amenajare peisagistică spectaculoasă, ce fel de
                        elemente de recreere ar fi mai la îndemână pentru tine. Și iată că punem accent pe creare de
                        comunitate care să aprecieze lucrul bine făcut: securitate, siguranță și intimitate.
                      </p>
                      <p className="m-b20">
                        <b>STRUCTURA:</b> Realizarea studiului geotehnic a indicat dimensionarea fundațiilor fiind
                        realizate la cota 1.50 m, ce asigură fundarea clădirii în terenul sănătos cât și adâncimea
                        minimă de îngheț de 90 cm. Structura de rezistență mixtă formată din cadre din beton la interior
                        și pereți portanți din zidărie cu stâlpi și centuri- perimetral. Planșeul peste parter este
                        format din placă cu grosime de 15cm ce descarcă pe grinzile de cadru și centurile din beton
                        monolit
                      </p>
                      <p>
                        <b>FACILITATI:</b> finisaje de calitate superioara, suprafete generoase, compartimentare ideala,
                        terase, centrala termica si calorifere ARISTON, obiecte sanitare complete, parchet Gresie si
                        fainata, usi intrare si tamplarie gri antracit, usi interioare, terase placate cu ceramica si
                        sistem de hidroizolatie MAPEI
                      </p>
                      <p>
                        <b>CONFORT:</b> compartimentari eficiente, spatii vitrate mari, utilitati bransate la retelele
                        publice, multitudine de facilitati
                      </p>
                      {/* <a href="https://www.youtube.com" className="popup-youtube m-r20 video btn btn-primary btn-video">
                        <i className="fa fa-play"></i>
                        <span></span>
                      </a> */}
                      <Link to={"/contact"} className="btn btn-primary">
                        Contact
                      </Link>
                    </div>
                    <div className="col-lg-7">
                      <div className="m-b30 mfp-gallery">
                        <OverlayBlog imageType={gal2} index={0} />
                      </div>
                      <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                        {detailBlog.map((data, index) => (
                          <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 p-lr0" key={index}>
                            <div className="pro-details">
                              {data.icon}
                              <strong>{data.title}</strong> {data.subtitle}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="m-b30 mfp-gallery">
                        <OverlayBlog imageType={gal3} index={1} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Project Info  End*/}
                {/*  Project Video Section */}
                {/* <section
                  className="m-t100 section-full content-inner-2 overlay-black-middle video-bx"
                  style={{ backgroundImage: "url(" + img2 + ")", backgroundSize: "cover" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center text-white">
                        <div className="video-play">
                          <VideoPopup2 />
                        </div>
                        <h2 className="video-title text-white">The Power to Make Anything</h2>
                        <p className="video-content">
                          Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum
                          formas humanitatis per seacula quarta decima et quinta decima.
                        </p>
                        <Link to={"/contact"} className="btn btn-primary radius-xl">
                          <span className="text-black">Contact</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section> */}
                {/*  Project Video Section End */}
                {/* Our Gallery  */}
                <div className="section-full content-inner-2">
                  <div className="container">
                    <div className="row our-gallery mfp-gallery">
                      <GallerySection />
                    </div>
                  </div>
                </div>
                {/* Our Gallery End */}
              </section>
            </SRLWrapper>
          </SimpleReactLightbox>

          <section className="content-inner-1" data-content="PLAN PROIECT" id="masterPlan">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title">Plan proiect</h2>
                <div className="dlab-separator bg-primary"></div>
                <p>
                  Cu finisaje premium și spații larg deschise, fiecare plan de vă aduce un lux de neegalat fără a
                  sacrifica confortul.
                  <br /> Planurile reprezintă redarea arhitectilor si designerilor. Toate dimensiunile sunt aproximative
                </p>
              </div>
            </div>
            <SwiperSlider galleryBlog={projectPlans} />
          </section>
          {/* Content Area End  */}
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}

function GallerySection() {
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={gal4} index={2} />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={gal5} index={3} />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={g2019_1} index={4} />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={g2019_2} index={5} />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={g2019_3} index={6} />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
        <OverlayBlog imageType={g2019_4} index={7} />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
        <OverlayBlog imageType={gal14} index={8} />
      </div>
      <div className="d-none col-lg-12 col-md-12 col-sm-12 m-b30">
        <OverlayBlog imageType={gal6} />
      </div>

      <div className="d-none col-lg-12 col-md-12 col-sm-12 m-b30">
        <OverlayBlog imageType={gal7} />
      </div>

      <div className="d-none">
        <OverlayBlog imageType={gal9} />
      </div>
      <div className="d-none">
        <OverlayBlog imageType={gal10} />
      </div>
      <div className="d-none">
        <OverlayBlog imageType={gal11} />
      </div>
      <div className="d-none">
        <OverlayBlog imageType={gal12} />
      </div>
      <div className="d-none">
        <OverlayBlog imageType={gal13} />
      </div>

      <div className="d-none">
        <OverlayBlog imageType={plan1} />
      </div>
      <div className="d-none">
        <OverlayBlog imageType={plan2} />
      </div>
    </>
  );
}
export { GallerySection };

export default Project2019;
