import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "../../Layout/Header";
import Footer2 from "../../Layout/Footer2";
import PageTitle from "../../Layout/PageTitle";
import { VideoPopup2 } from "../../Element/VideoPopup";

import videoFundal from "./../../../images/2021/video_fundal.jpeg";

import g2022_1 from "./../../../images/2022/g2022-1.jpeg";
import g2022_2 from "./../../../images/2022/g2022-2.jpeg";
import g2022_3 from "./../../../images/2022/g2022-3.jpeg";
import g2022_4 from "./../../../images/2022/g2022-4.jpeg";
import g2022_5 from "./../../../images/2022/g2022-5.jpeg";
import g2022_6 from "./../../../images/2022/g2022-6.jpeg";
import g2022_7 from "./../../../images/2022/g2022-7.jpeg";
import g2022_8 from "./../../../images/2022/g2022-8.jpeg";
import g2022_9 from "./../../../images/2022/g2022-9.jpeg";
import g2022_10 from "./../../../images/2022/g2022-10.jpeg";
import g2022_11 from "./../../../images/2022/g2022-11.jpeg";
import g2022_12 from "./../../../images/2022/g2022-12.jpeg";
import g2022_13 from "./../../../images/2022/g2022-13.jpeg";
import g2022_14 from "./../../../images/2022/g2022-14.jpeg";
import g2022_15 from "./../../../images/2022/g2022-15.jpeg";
import g2022_16 from "./../../../images/2022/g2022-16.jpeg";
import g2022_17 from "./../../../images/2022/g2022-17.jpeg";
import g2022_18 from "./../../../images/2022/g2022-18.jpeg";
import g2022_19 from "./../../../images/2022/g2022-19.jpeg";
import g2022_20 from "./../../../images/2022/g2022-20.jpeg";
import g2022_21 from "./../../../images/2022/g2022-21.jpeg";
import g2022_22 from "./../../../images/2022/g2022-22.jpeg";
import g2022_23 from "./../../../images/2022/g2022-23.jpeg";
import g2022_24 from "./../../../images/2022/g2022-24.jpeg";
import g2022_25 from "./../../../images/2022/g2022-25.jpeg";
import g2022_26 from "./../../../images/2022/g2022-26.jpeg";
import g2022_27 from "./../../../images/2022/g2022-27.jpeg";
import g2022_28 from "./../../../images/2022/g2022-28.jpeg";
import g2022_29 from "./../../../images/2022/g2022-29.jpeg";
import g2022_30 from "./../../../images/2022/g2022-30.jpeg";
import g2022_31 from "./../../../images/2022/g2022-31.jpeg";
import g2022_32 from "./../../../images/2022/g2022-32.jpeg";

import plan1 from "./../../../images/2022/g2022-plan1.png";
import plan2 from "./../../../images/2022/g2022-plan2.png";

import SwiperSlider from "../Aboutus/SwiperSlider";

//Light Gallery on icon click
const Iconimage = (props) => {
	const { openLightbox } = useLightbox();

	return (
		<Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="lightimg ">
			<i className="ti-zoom-in icon-bx-xs"></i>
		</Link>
	);
};

const detailBlog = [
	{
		icon: <i className="ti ti-location-pin" />,
		title: "LOCATIE",
		subtitle: "BRADU - PRIMĂRIE, Strada Principala 378",
	},
	{ icon: <i className="ti ti-ruler-alt-2" />, title: "MĂRIME PROIECT", subtitle: "5000 mp" },
];

const projectPlans = [{ image: plan1 }, { image: plan2 }];

const OverlayBlog = ({ imageType, index }) => {
	return (
		<>
			<div className="dlab-box gallery-box-2">
				<div className="dlab-media dlab-img-overlay1 dlab-img-effect">
					<img src={imageType} alt="" />
					<div className="overlay-bx">
						<Iconimage imageToOpen={index} />
					</div>
				</div>
			</div>
		</>
	);
};

class Project2022 extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white" id="lightgallery">
					{/*  banner  */}
					<PageTitle motherMenu="Proiect 2022" activeMenu="Proiect 2022" />
					{/*  Project Img  */}
					<SimpleReactLightbox>
						<SRLWrapper>
							{/* <div className="section-full content-inner-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mfp-gallery">
                      <OverlayBlog imageType={img1} />
                    </div>
                  </div>
                </div>
              </div> */}
							{/*  Project Img End */}
							{/* Project Info  */}

							<section className="section-full content-inner">
								<div className="container">
									<div className="row">
										<div className="col-lg-5 m-b30">
											<div className="section-head mb-3">
												<p>2022</p>
												<h4 className="title">MLC RESIDENCE 7</h4>
											</div>
											<p>
												Asa cum v-a obisnuit, compania MLC RESIDENCE lansează noul proiect rezidențial în BRADU,
												proiectul numarul 7 ce cuprinde 7 locuințe. Proiectul va fi construit în BRADU PRIMARIE, Strada
												Principala 378, si deține un teren de aproximativ 5000 mp.
											</p>
											<p className="m-b20">
												Asa cum v-am obisnuit an de an, noul proiect promite conectarea cartierului MLC RESIDENCE 7 cu
												celelalte cartiere din Pitesti și centrul orașului prin multiplele cai de acces inspre si
												dinspre oras datorita pozitiei. Noul proiect va fi in centrul comunei Bradu, in zona zero, cu
												deschidere la DJ, cu cele mai bune facilitati ale zonei: asfalt, utilitati, unitati
												administrative (primarie, dispensar, scoli, stadion, sala de sport), transport in comun,
												magazine, etc.
											</p>
											<p className="m-b20">
												Lansarea MLC RESIDENCE 7 este o dovadă că suntem optimiști în ceea ce privește evoluția pieței
												imobiliare locale. Mai mult decât atât, aceasta investiție este doar o parte a planurilor pe
												care le avem în viitorul apropiat.
											</p>
											<p>
												<b>Misiunea noastra?</b> Dezvoltarea zonelor, proiecte imobiliare deosebite prin arhitectura si
												rezistenta . De aceea, vom dezvolta noul complex rezidențial MLC RESIDENCE 7 - BRADU, într-o
												zonă cu acces rapid la artere rutiere importate, mijloacele de trasport în comun și cu numeroase
												facilități în apropiere: școli, grădinițe, magazine. Prin modul în care este poziționat
												proiectul vei putea avea parte de intimitate dar și avantajul de a ajunge în mai puțin de 10
												minute până în centrul orasului .
											</p>
											<p className="m-b20">
												Imobilele au suprafețe de 120 mp si teren 400 mp și se vând la cheie, cu finisaje de calitate
												superioara, branșamente individuale si imprejmuire. Fiecare unitate imobiliara este compusa din
												4 camere spatioase ( living +3 dormitoare ), 2 bai si zona de bucatarie.
											</p>
											<p>
												<b>Structura de rezistenta</b> este executata cu betoane de calitate conform normativelor in
												vigoare de la statii de betoane de prestigiu - HIDRO ARGES, CĂRĂMIDĂ PORTANTA POROTHERM ROBUST,
												placare exterioara cu polistiren de 10 cm AUSTROTHERM, vopsea decorativa, tabla BILKA RAL 8019,
												tamplarie stejar auriu, incalzire in pardoseala, sape, tencuiala mecanizata, placari ceramice de
												calitate, etc. Proiectul de arhitectura este realizat de Silvora Tera.
											</p>
											{/* <a href="https://www.youtube.com" className="popup-youtube m-r20 video btn btn-primary btn-video">
                        <i className="fa fa-play"></i>
                        <span></span>
                      </a> */}
											<Link to={"/contact"} className="btn btn-primary">
												Contact
											</Link>
										</div>
										<div className="col-lg-7">
											<div className="m-b30 mfp-gallery">
												<OverlayBlog imageType={g2022_1} index={0} />
											</div>
											<div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
												{detailBlog.map((data, index) => (
													<div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 p-lr0" key={index}>
														<div className="pro-details">
															{data.icon}
															<strong>{data.title}</strong> {data.subtitle}
														</div>
													</div>
												))}
											</div>
											<div className="mt-3 pt-4 mfp-gallery">
												<OverlayBlog imageType={g2022_2} index={1} />
											</div>
										</div>
									</div>
								</div>
								{/* Project Info  End*/}
								{/* Our Gallery End */}
							</section>

							<section className="content-inner-1" data-content="GALERIE">
								<div className="container">
									<div className="section-head text-center">
										<h2 className="title">Galerie</h2>
										<div className="dlab-separator bg-primary"></div>
									</div>
									<div className="row our-gallery mfp-gallery">
										<GallerySection />
									</div>
								</div>
							</section>
						</SRLWrapper>
					</SimpleReactLightbox>

					<section className="content-inner-1" data-content="PLAN PROIECT" id="masterPlan">
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title">Plan proiect</h2>
								<div className="dlab-separator bg-primary"></div>
								<p>
									Cu finisaje premium și spații larg deschise, fiecare plan de vă aduce un lux de neegalat fără a
									sacrifica confortul.
									<br /> Planurile reprezintă redarea arhitectilor si designerilor. Toate dimensiunile sunt aproximative
								</p>
							</div>
						</div>
						<SwiperSlider galleryBlog={projectPlans} />
					</section>
					{/* Content Area End  */}
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}

function GallerySection() {
	return (
		<>
			{/* <div className="col-lg-12 col-md-12 col-sm-12 m-b30">
        <OverlayBlog imageType={pic2} />
      </div> */}
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_3} index={2} />
			</div>

			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_4} index={3} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_5} index={4} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_6} index={5} />
			</div>

			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_7} index={6} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_8} index={7} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_9} index={8} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_10} index={9} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_11} index={10} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_12} index={11} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_13} index={12} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_14} index={13} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_15} index={14} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_16} index={15} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_17} index={16} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_18} index={17} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_19} index={18} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_20} index={19} />
			</div>

			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_21} index={9} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_22} index={11} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_23} index={12} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_24} index={13} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_25} index={14} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_26} index={15} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_27} index={16} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_28} index={17} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_29} index={18} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_30} index={19} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_31} index={19} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2022_32} index={19} />
			</div>

			<div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan1} />
			</div>

			<div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan2} />
			</div>
		</>
	);
}
export { GallerySection };

export default Project2022;
