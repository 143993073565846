import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "../../Layout/Header";
import Footer2 from "../../Layout/Footer2";
import PageTitle from "../../Layout/PageTitle";
import { VideoPopup2 } from "../../Element/VideoPopup";

import videoFundal from "./../../../images/2021/video_fundal.jpeg";

import p2023_1 from "./../../../images/2023/p2023-1.jpeg";
import p2023_2 from "./../../../images/2023/p2023-2.jpeg";
import p2023_3 from "./../../../images/2023/p2023-3.jpeg";
import p2023_4 from "./../../../images/2023/p2023-4.jpeg";
import p2023_5 from "./../../../images/2023/p2023-5.jpeg";
import p2023_6 from "./../../../images/2023/p2023-6.jpeg";
import p2023_7 from "./../../../images/2023/p2023-7.jpeg";
import p2023_8 from "./../../../images/2023/p2023-8.jpeg";
import p2023_9 from "./../../../images/2023/p2023-9.jpeg";
import p2023_10 from "./../../../images/2023/p2023-10.jpeg";
import p2023_11 from "./../../../images/2023/p2023-11.jpeg";
import p2023_12 from "./../../../images/2023/p2023-12.jpeg";
import p2023_13 from "./../../../images/2023/p2023-13.jpeg";
import p2023_14 from "./../../../images/2023/p2023-14.jpeg";
import p2023_15 from "./../../../images/2023/p2023-15.jpeg";
import p2023_16 from "./../../../images/2023/p2023-16.jpeg";
import p2023_17 from "./../../../images/2023/p2023-17.jpeg";
import p2023_18 from "./../../../images/2023/p2023-18.jpeg";
import p2023_19 from "./../../../images/2023/p2023-19.jpeg";
import p2023_20 from "./../../../images/2023/p2023-20.jpeg";
import p2023_21 from "./../../../images/2023/p2023-21.jpeg";
import p2023_22 from "./../../../images/2023/p2023-22.jpeg";
import p2023_23 from "./../../../images/2023/p2023-23.jpeg";
import p2023_24 from "./../../../images/2023/p2023-24.jpeg";
import p2023_25 from "./../../../images/2023/p2023-25.jpeg";
import p2023_26 from "./../../../images/2023/p2023-26.jpeg";
import p2023_27 from "./../../../images/2023/p2023-27.jpeg";
import p2023_28 from "./../../../images/2023/p2023-28.jpeg";
import p2023_29 from "./../../../images/2023/p2023-29.jpeg";
import p2023_30 from "./../../../images/2023/p2023-30.jpeg";
import p2023_31 from "./../../../images/2023/p2023-31.jpeg";
import p2023_32 from "./../../../images/2023/p2023-32.jpeg";
import p2023_33 from "./../../../images/2023/p2023-33.jpeg";
import p2023_34 from "./../../../images/2023/p2023-34.jpeg";
import p2023_35 from "./../../../images/2023/p2023-35.jpeg";
import p2023_36 from "./../../../images/2023/p2023-36.jpeg";
import p2023_37 from "./../../../images/2023/p2023-37.jpeg";
import p2023_38 from "./../../../images/2023/p2023-38.jpeg";
import p2023_39 from "./../../../images/2023/p2023-39.jpeg";
import p2023_40 from "./../../../images/2023/p2023-40.jpeg";
import p2023_41 from "./../../../images/2023/p2023-41.jpeg";
import p2023_42 from "./../../../images/2023/p2023-42.jpeg";
import p2023_43 from "./../../../images/2023/p2023-43.jpeg";
import p2023_44 from "./../../../images/2023/p2023-44.jpeg";
import p2023_45 from "./../../../images/2023/p2023-45.jpeg";
import p2023_46 from "./../../../images/2023/p2023-46.jpeg";
import p2023_47 from "./../../../images/2023/p2023-47.jpeg";
import p2023_48 from "./../../../images/2023/p2023-48.jpeg";
import p2023_49 from "./../../../images/2023/p2023-49.jpeg";
import p2023_50 from "./../../../images/2023/p2023-50.jpeg";

import plan1 from "./../../../images/2023/plan1.jpeg";
import exterior1 from "./../../../images/2023/exterior1.jpeg";
import exterior2 from "./../../../images/2023/exterior2.jpeg";
import exterior3 from "./../../../images/2023/exterior3.jpeg";
// import exterior4 from "./../../../images/2023/exterior4.jpeg";
import exterior5 from "./../../../images/2023/exterior5.jpeg";
import exterior6 from "./../../../images/2023/exterior6.jpeg";
import exterior7 from "./../../../images/2023/exterior7.jpeg";

import SwiperSlider from "../Aboutus/SwiperSlider";

//Light Gallery on icon click
const Iconimage = (props) => {
	const { openLightbox } = useLightbox();

	return (
		<Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="lightimg ">
			<i className="ti-zoom-in icon-bx-xs"></i>
		</Link>
	);
};

const detailBlog = [
	{
		icon: <i className="ti ti-location-pin" />,
		title: "LOCATIE",
		subtitle: "Strada Principala nr 378, Bradu Argeș, lângă Primărie",
	},
	{ icon: <i className="ti ti-ruler-alt-2" />, title: "MĂRIME PROIECT", subtitle: "6000 mp" },
];

const projectPlans = [{ image: plan1 }];

const OverlayBlog = ({ imageType, index }) => {
	return (
		<>
			<div className="dlab-box gallery-box-2">
				<div className="dlab-media dlab-img-overlay1 dlab-img-effect">
					<img src={imageType} alt="" />
					<div className="overlay-bx">
						<Iconimage imageToOpen={index} />
					</div>
				</div>
			</div>
		</>
	);
};

class Project2023 extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white" id="lightgallery">
					{/*  banner  */}
					<PageTitle motherMenu="Proiect 2023" activeMenu="Proiect 2023" />
					{/*  Project Img  */}
					<SimpleReactLightbox>
						<SRLWrapper>
							{/* <div className="section-full content-inner-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mfp-gallery">
                      <OverlayBlog imageType={img1} />
                    </div>
                  </div>
                </div>
              </div> */}
							{/*  Project Img End */}
							{/* Project Info  */}

							<section className="section-full content-inner">
								<div className="container">
									<div className="row">
										<div className="col-lg-5 m-b30">
											<div className="section-head mb-3">
												<p>2023</p>
												<h4 className="title">MLC RESIDENCE 7</h4>
											</div>
											<p>
												Atunci când caută o locuință oamenii au speranța că viața lor se va schimba în bine și vor găsi
												un motiv în plus pentru a fi fericiți. Ne-am convins că timpul petrecut în natură în compania
												celor dragi aduce bucurie și sănătate! De aceea, vom dezvolta complexul rezidențial MLC
												RESIDENCE - BRADU, într-o zonă cu acces rapid la artere rutiere importate, mijloacele de
												trasport în comun și cu numeroase facilități în apropiere: școli, grădinițe, magazine. Prin
												modul în care este poziționat proiectul vei putea avea parte de intimitate dar și avantajul de a
												ajunge în mai puțin de 10 minute până în centrul orasului.
											</p>
											<p className="m-b20">
												<b>CONSULTANTA FINANCIARA GRATUITA</b> Consultanții cu care colaborăm te ajută să găsești cele
												mai bune oferte bancare din piață deoarece contextul financiar este în continuă schimbare. Vei
												reduce semnificativ timpul necesar pentru pregătirea unei oferte personalizate de finanțare.
												Echipa noastră te va asista la întocmirea dosarului de credit, va prelua legătura cu banca, pe
												care a identificat-o împreună cu tine ca având cea mai bună ofertă și îți va oferi suport până
												la accesarea finală a creditului.
											</p>
											<p className="m-b20">
												<b>MLC RESIDENCE GROUP</b> oferă locuințe confortabile și eficient compartimentate, la prețuri
												excelente! Complexul de locuințe MLC 7, beneficiază de următoarele avantaje:
												<br />
												- siguranța și avantajul locuirii într-o comunitate omogenă
												<br />
												- zonă rezidențiala nouă în centrul comunei Bradu
												<br />
												- beneficiile tuturor utilităților unui oraș, apă, canalizare, gaze naturale, electricitate,
												distanță mica și acces rapid către /dinspre Pitești
												<br />
												- transport urban metropolitan, având stație în fața complexului
												<br />
												- finisaje moderne de cea mai buna calitate
												<br />
												- unități imobiliare aerisite, curți generoase
												<br />- Primarie dispensar, școală, creșă, grădinițe, sala de sport, stadion, farmacii,
												pizzerie, magazine, distanță mică față de Auchan
											</p>
											{/* <a href="https://www.youtube.com" className="popup-youtube m-r20 video btn btn-primary btn-video">
                        <i className="fa fa-play"></i>
                        <span></span>
                      </a> */}
											<Link to={"/contact"} className="btn btn-primary">
												Contact
											</Link>
										</div>
										<div className="col-lg-7">
											<div className="m-b30 mfp-gallery">
												<OverlayBlog imageType={p2023_22} index={0} />
											</div>
											<div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
												{detailBlog.map((data, index) => (
													<div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 p-lr0" key={index}>
														<div className="pro-details">
															{data.icon}
															<strong>{data.title}</strong> {data.subtitle}
														</div>
													</div>
												))}
											</div>
											<div className="mt-3 pt-4 mfp-gallery">
												<OverlayBlog imageType={p2023_24} index={1} />
											</div>
										</div>
									</div>
								</div>
								{/* Project Info  End*/}
								{/* Our Gallery End */}
							</section>

							<section className="content-inner-1" data-content="GALERIE">
								<div className="container">
									<div className="section-head text-center">
										<h2 className="title">Galerie</h2>
										<div className="dlab-separator bg-primary"></div>
									</div>
									<div className="row our-gallery mfp-gallery">
										<GallerySection />
									</div>
								</div>
							</section>
						</SRLWrapper>
					</SimpleReactLightbox>

					<section className="content-inner-1" data-content="PLAN PROIECT" id="masterPlan">
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title">Plan proiect</h2>
								<div className="dlab-separator bg-primary"></div>
								<p>
									Cu finisaje premium și spații larg deschise, fiecare plan de vă aduce un lux de neegalat fără a
									sacrifica confortul.
									<br /> Planurile reprezintă redarea arhitectilor si designerilor. Toate dimensiunile sunt aproximative
								</p>
							</div>
						</div>
						<SwiperSlider galleryBlog={projectPlans} />
					</section>
					{/* Content Area End  */}
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}

function GallerySection() {
	return (
		<>
			{/* <div className="col-lg-12 col-md-12 col-sm-12 m-b30">
        <OverlayBlog imageType={pic2} />
      </div> */}
			<div className="card-columns">
				<div className="m-b30">
					<OverlayBlog imageType={exterior1} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={exterior2} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={exterior5} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={exterior7} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={exterior6} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={exterior3} index={2} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_24} index={3} />
				</div>
			</div>

			<div className="card-columns mt-4">
				<div className="m-b30">
					<OverlayBlog imageType={p2023_23} index={2} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_25} index={4} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_26} index={5} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_27} index={6} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_28} index={7} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_29} index={8} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_30} index={9} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_31} index={10} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_32} index={11} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_33} index={12} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_34} index={13} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_35} index={14} />
				</div>
			</div>

			<div className="card-columns mt-4">
				<div className="m-b30">
					<OverlayBlog imageType={p2023_1} index={15} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_2} index={16} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_3} index={17} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_4} index={18} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_5} index={19} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_6} index={20} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_7} index={21} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_8} index={22} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_9} index={23} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_10} index={24} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_11} index={25} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_12} index={26} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_13} index={27} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_14} index={28} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_15} index={29} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_16} index={30} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_17} index={30} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_18} index={31} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_19} index={32} />
				</div>
			</div>

			<div className="card-columns mt-4">
				<div className="m-b30">
					<OverlayBlog imageType={p2023_36} index={33} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_37} index={34} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_38} index={35} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_39} index={36} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_40} index={37} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_41} index={38} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_42} index={39} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_43} index={40} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_44} index={41} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_45} index={42} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_46} index={43} />
				</div>

				<div className="m-b30">
					<OverlayBlog imageType={p2023_47} index={44} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_48} index={45} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_49} index={46} />
				</div>
				<div className="m-b30">
					<OverlayBlog imageType={p2023_50} index={47} />
				</div>
			</div>

			{/* <div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan1} />
			</div>

			<div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan2} />
			</div> */}
		</>
	);
}
export { GallerySection };

export default Project2023;
