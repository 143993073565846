import React, { Component } from "react";
import { Link } from "react-scroll";

class SideNav extends Component {
  render() {
    return (
      <>
        <ul className="navbar">
          <li>
            <Link
              activeClass="active"
              to="sidenav_home"
              smooth={true}
              offset={-70}
              duration={500}
              className="scroll nav-link "
            >
              <i className="las la-home text-white"></i> <span className="text-white">Acasa</span>
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="sidenav_specifications"
              className="scroll nav-link"
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="las la-file-alt text-white"></i> <span className="text-white">Specificatii</span>
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="sidenav_aboutUs"
              className="scroll nav-link "
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="las la-user text-white"></i> <span className="text-white">Despre noi</span>
            </Link>
          </li>
          {/* <li>
						<Link 
							activeClass="active" to="sidenav_masterPlan" className="scroll nav-link"  smooth={true} offset={-70} duration={500}  >
							<i className="las la-chart-bar text-white"></i> <span className="text-white">MASTER PLAN</span>
						</Link>
					</li> */}
          {/* <li>
            <Link
              activeClass="active"
              to="sidenav_mainGallery"
              className="scroll nav-link"
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="las la-image text-white"></i> <span className="text-white">Galerie</span>
            </Link>
          </li> */}
          {/* <li>
            <Link
              activeClass="active"
              to="sidenav_ourServices"
              className="scroll nav-link"
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="las la-cog text-white"></i> <span className="text-white">Serviciile noastre</span>
            </Link>
          </li> */}
          {/* <li>
						<Link 
							activeClass="active" to="sidenav_newsEvent" className="scroll nav-link"  smooth={true} offset={-70} duration={500}  >
							<i className="las la-rss text-white "></i> <span className="text-white">News Event</span>
						</Link>
					</li> */}
          {/* <li>
            <Link
              activeClass="active"
              to="sidenav_footer"
              className="scroll nav-link"
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="las la-phone-volume text-white"></i> <span className="text-white">Contact</span>
            </Link>
          </li> */}
        </ul>
      </>
    );
  }
}

export default SideNav;
