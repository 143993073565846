import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import galery1 from "../../images/2020/exterior1_2020.jpeg";
import galery2 from "../../images/2019/2019-1.jpeg";
import galery4 from "../../images/2019/2019-2.jpeg";
import galery5 from "../../images/2019/exteriorFinalizat4_2019.jpeg";
import galery6 from "../../images/2019/2019-interior-1.jpeg";
import g2019_1 from "../../images/2019/g2019-1.jpeg";
import g2019_2 from "../../images/2019/g2019-2.jpeg";
import g2019_3 from "../../images/2019/g2019-3.jpeg";
import g2019_4 from "../../images/2019/g2019-4.jpeg";

// import galery2 from "../../images/2021/exterior3.jpeg";
import galery3 from "../../images/2020/2020_exterior4.jpeg";
import galery8 from "../../images/2020/exterior3_2020.jpeg";
import galery9 from "../../images/2020/2020-interior-3.jpeg";
import galery10 from "../../images/2020/2020-peisaj-1.jpeg";
import galery11 from "../../images/2020/2020-constructie-1.jpeg";
import g2020_1 from "../../images/2020/20200926_145815.jpg";
import g2020_2 from "../../images/2020/20200926_145754.jpg";
import g2020_3 from "../../images/2020/20200926_145544.jpg";
import g2020_4 from "../../images/2020/g2020-4.jpeg";
import g2020_5 from "../../images/2020/g2020-5.jpeg";
import g2020_6 from "../../images/2020/g2020-6.jpeg";
import g2020_7 from "../../images/2020/20201102_175907.jpg";
import g2020_8 from "../../images/2020/20201102_175836.jpg";
import g2020_9 from "../../images/2020/20200926_145905.jpg";

import galery12 from "../../images/2021/exterior3.jpeg";
import g2021_1 from "../../images/2021/g2021-1.jpeg";
import g2021_2 from "../../images/2021/g2021-2.jpeg";
import g2021_3 from "../../images/2021/g2021-3.jpeg";
import g2021_4 from "../../images/2021/g2021-4.jpeg";
import g2021_5 from "../../images/2021/g2021-5.jpeg";
import g2021_6 from "../../images/2021/g2021-6.jpeg";
import g2021_7 from "../../images/2021/g2021-7.jpeg";
import g2021_8 from "../../images/2021/g2021-8.jpeg";
import g2021_9 from "../../images/2021/g2021-9.jpeg";
import g2020_10 from "../../images/2020/1620935127675_image2.jpeg";
import g2020_11 from "../../images/2020/1620935127681_image0.jpeg";
import g2020_12 from "../../images/2020/1620935111751_image0.jpeg";

const latestBlog = [
  { image: galery1, title: "2019", link: "/proiect-2019" },
  { image: galery4, title: "2019", link: "/proiect-2019" },
  { image: galery2, title: "2019", link: "/proiect-2019" },
  { image: galery5, title: "2019", link: "/proiect-2019" },
  { image: galery6, title: "2019", link: "/proiect-2019" },
  { image: g2019_1, title: "2019", link: "/proiect-2019" },
  { image: g2019_2, title: "2019", link: "/proiect-2019" },
  { image: g2019_3, title: "2019", link: "/proiect-2019" },
  { image: g2019_4, title: "2019", link: "/proiect-2019" },

  { image: galery8, title: "2020", link: "/proiect-2020" },
  { image: galery10, title: "2020", link: "/proiect-2020" },
  { image: galery9, title: "2020", link: "/proiect-2020" },
  { image: galery11, title: "2020", link: "/proiect-2020" },
  { image: galery3, title: "2020", link: "/proiect-2020" },
  { image: g2020_1, title: "2020", link: "/proiect-2020" },
  { image: g2020_2, title: "2020", link: "/proiect-2020" },
  { image: g2020_3, title: "2020", link: "/proiect-2020" },
  { image: g2020_4, title: "2020", link: "/proiect-2020" },
  { image: g2020_5, title: "2020", link: "/proiect-2020" },
  { image: g2020_6, title: "2020", link: "/proiect-2020" },
  { image: g2020_7, title: "2020", link: "/proiect-2020" },
  { image: g2020_8, title: "2020", link: "/proiect-2020" },
  { image: g2020_9, title: "2020", link: "/proiect-2020" },
  { image: g2020_10, title: "2020", link: "/proiect-2020" },
  { image: g2020_11, title: "2020", link: "/proiect-2020" },
  { image: g2020_12, title: "2020", link: "/proiect-2020" },

  { image: galery12, title: "2021", link: "/proiect-2021" },
  { image: g2021_1, title: "2021", link: "/proiect-2021" },
  { image: g2021_2, title: "2021", link: "/proiect-2021" },
  { image: g2021_3, title: "2021", link: "/proiect-2021" },
  { image: g2021_4, title: "2021", link: "/proiect-2021" },
  { image: g2021_5, title: "2021", link: "/proiect-2021" },
  { image: g2021_6, title: "2021", link: "/proiect-2021" },
  { image: g2021_7, title: "2021", link: "/proiect-2021" },
  { image: g2021_8, title: "2021", link: "/proiect-2021" },
  { image: g2021_9, title: "2021", link: "/proiect-2021" },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
    </div>
  );
}

function GalleryBox(props) {
  const { item, index } = props;
  return (
    <div className="items" key={index} style={{ cursor: "pointer" }}>
      <div className="amenit-box">
        <div className="media w-100">
          <img src={item.image} alt="" className="slider-image" srl_gallery_image="true" />
        </div>
        <div className="info">
          <h5 className="title">
            <i className="ti-home mr-0"></i>
            {/* <span>
    <Link to={item.link}>{item.title}</Link>
  </span> */}
          </h5>
        </div>
      </div>
    </div>
  );
}

class Slider1 extends Component {
  render() {
    var settings = {
      arrows: false,
      centerMode: true,
      centerPadding: "500px",
      slidesToShow: 1,
      speed: 3000,
      navSpeed: 3000,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1,
            centerPadding: "400px",
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: "200px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: "90px",
          },
        },
      ],
    };
    return (
      <SimpleReactLightbox>
        <SRLWrapper>
          <Slider className="amenities-carousel owl-carousel owl-btn-center-lr " {...settings}>
            {latestBlog.map((item, index) => (
              <GalleryBox item={item} index={index} />
            ))}
          </Slider>
        </SRLWrapper>
      </SimpleReactLightbox>
    );
  }
}

export default Slider1;
