import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import logo1 from "./../../images/logo/logo1.png";
import logo2 from "./../../images/logo/logo2.png";
import logo3 from "./../../images/logo/logo3.png";
import logo4 from "./../../images/logo/logo4.png";
import logowhite from "./../../images/logo-white.png";

class Footer extends Component {
	render() {
		return (
			<Fragment>
				<footer className="site-footer" id="sidenav_footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
									<Footercol1 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
									<Footercol2 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
									<Footercol3 />
								</div>
							</div>
						</div>
					</div>
					{/*  footer bottom part */}
					<div className="footer-bottom">
						<Footerbottom />
					</div>
				</footer>
			</Fragment>
		);
	}
}
function Footercol1() {
	return (
		<>
			<div className="widget widget_about">
				{/* <div className="footer-logo">
					<Link to="./"><img src={logowhite} alt=""/></Link> 
				</div> */}
				<h5 className="footer-title">MLC Residence</h5>

				<p>
					Înconjurați-vă cu energie pozitiva , facilități de înaltă tehnologie și stil de viata ridicat. Răsfățați-vă cu
					facilități extraordinare, relaxați-vă în spații sociale atrăgătoare
				</p>
				<div className="dlab-social-icon">
					<ul>
						<li>
							<a href={"https://www.facebook.com/MlcResidence/"} className="fa fa-facebook mr-1" target="_blank" />
						</li>
						<li>
							<a href={"https://goo.gl/maps/bE1Co4aCykobcdbP6"} className="fa fa-map-marker mr-1" target="_blank" />
						</li>
						{/* <li>
              <Link to={"#"} className="fa fa-twitter mr-1"></Link>
            </li>
            <li>
              <Link to={"#"} className="fa fa-linkedin mr-1"></Link>
            </li>
            <li>
              <Link to={"#"} className="fa fa-instagram"></Link>
            </li> */}
					</ul>
				</div>
			</div>
		</>
	);
}

function Footercol2() {
	return (
		<>
			<div className="widget">
				<h5 className="footer-title">Contacteaza-ne</h5>
				<ul className="contact-info-bx">
					<li>
						<i className="las la-map-marker"></i>
						BRADU, Strada Principala nr. 378
					</li>
					<li>
						<i className="las la-phone-volume"></i>
						0752060734 | 0723621665
					</li>
				</ul>
			</div>
		</>
	);
}

function Footercol3() {
	return (
		<>
			<div className="widget widget-logo">
				<h5 className="footer-title">Canalele noastre de business</h5>
				<ul>
					<li>
						<Link to={"#"}>
							<img src={logo1} alt="" />
						</Link>
					</li>
					<li>
						<Link to={"#"}>
							<img src={logo2} alt="" />
						</Link>
					</li>
					<li>
						<Link to={"#"}>
							<img src={logo3} alt="" />
						</Link>
					</li>
					<li>
						<Link to={"#"}>
							<img src={logo4} alt="" />
						</Link>
					</li>
				</ul>
			</div>
		</>
	);
}

function Footerbottom() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-12 text-md-left text-center">
						{" "}
						<span>© 2021 Mlc Residence. All Right Reserved</span>{" "}
					</div>
					<div className="col-md-6 col-sm-12 text-md-right text-center">
						<div className="widget-link ">
							<ul>
								<li>
									<Link to={"/about-us"}> Despre noi</Link>
								</li>
								<li>
									<Link to={"/contact"}> Contact</Link>
								</li>
								{/* <li>
                  <Link to={"/privacy-policy"}> Privacy Policy</Link>
                </li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export { Footercol1, Footercol2, Footercol3, Footerbottom };
export default Footer;
