import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";

import Header2 from "./../../Layout/Header2";
import Footer2 from "./../../Layout/Footer2";
import SideNav from "./../../Element/SideNav";
import VideoPopup from "./../../Element/VideoPopup";

import Slider1 from "./../../Element/Slider1";
import Mapview from "./../../Element/Mapview";
import EventSection from "./EventSection";
import SpecificationsBlog from "./SpecificationsBlog";
import SitePlanTab from "./SitePlanTab";

//Images
import SliderImg from "./../../../images/main-slider/slider1.png";
import main1 from "./../../../images/gallery/main1.jpg";

import structura from "./../../../images/specifications/mlc-structura.jpeg";
import fundatia from "./../../../images/specifications/mlc-fundatia.jpeg";
import finisajeExterioare from "./../../../images/specifications/mlc-finisaje-exterioare.jpeg";
import tamplarie from "./../../../images/specifications/mlc-tamplarie.jpeg";
import interior from "./../../../images/specifications/mlc-interior.jpeg";
import invelitori from "./../../../images/specifications/mlc-invelitori.jpeg";
import imprejmuire from "./../../../images/specifications/mlc-imprejmuire.jpeg";

// import logo from "./../../../images/logo/mlc-residence-logo.jpg";
import logo from "./../../../images/logo/mlc-residence-group-logo.jpg";
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "simple-react-lightbox";

function SpecificationsGalleryBox() {
	const { openLightbox } = useLightbox();

	return (
		<SimpleReactLightbox>
			<SRLWrapper>
				<img src={structura} id="structura" className="active" alt="" />
				<img src={fundatia} id="fundatia" alt="" />
				<img src={finisajeExterioare} id="finisaje-exterioare" alt="" />
				<img src={tamplarie} id="tamplarie" alt="" />
				<img src={interior} id="interior" alt="" />
				<img src={invelitori} id="invelitori" alt="" />
				<img src={imprejmuire} id="imprejmuire" alt="" />
			</SRLWrapper>
		</SimpleReactLightbox>
	);
}

class Index1 extends React.Component {
	componentDidMount() {
		new WOW.WOW().init();

		var GalleryCategory = document.querySelectorAll(".gallery-category .items");
		var GalelryMedia = document.querySelectorAll(".gallery-img img");

		var fch = [].slice.call(GalleryCategory);
		var fcMedia = [].slice.call(GalelryMedia);

		for (var y = 0; y < fch.length; y++) {
			fch[y].addEventListener("mouseenter", function () {
				galleryActive(this);
			});
		}

		function galleryActive(current) {
			fcMedia.forEach((el) => el.classList.remove("active"));

			setTimeout(() => {
				var dataImageBx = current.getAttribute("data-image-bx");
				document.querySelector("#" + dataImageBx).classList.add("active");
			}, 100);
		}
	}
	render() {
		return (
			<Fragment>
				<div className="sidenav-list">
					<SideNav />
				</div>

				<Header2 />
				{/* <!-- Main Slider --> */}
				<div className="page-content bg-white">
					<div
						className="banner-three "
						style={{ backgroundImage: "url(" + SliderImg + ")", backgroundSize: "cover" }}
						data-content="HOME"
						id="sidenav_home"
					>
						<div className="container">
							<div className="row align-items-center banner-inner ">
								<div className="col-md-6">
									<div className="content-blog" style={{ height: "200px" }}>
										<img src={logo} className="shadow" style={{ borderRadius: "50px", height: "100%" }} />
										{/* <h1 className=" title  m-b20">
                        Helping your house
                        <br />
                        become better
                        <br />
                        acquainted{" "}
                      </h1>
                      <Link to={"/contact-us"} className=" btn btn-primary">
                        Contact
                      </Link> */}
									</div>
									{/* <VideoPopup /> */}
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Main Slider End--> */}

					{/* Section-1  Start*/}
					<section className="amenities-area">
						<Slider1 />
					</section>
					{/* Section-1  End*/}

					{/* Section-2  Start*/}
					<section className="content-inner-2" data-content="SPECIFICATII" id="sidenav_specifications">
						<div className="container">
							<div className="section-head head-col">
								<h2 className="title nowrap">SPECIFICATII</h2>
								<p>
									MLC RESIDENCE (re)inventează modul de a trai bine, inspirat de trendul orasului Pitești si profitând
									de avantajele locațiilor unde se află amplasate, am combinat spațiul si nevoia de relaxare cu terase
									și curți generoase, și cu beneficiile speciale ale unor imobile având standard ridicat care oferă
									adițional, priveliști de ansamblu și intimitate complete.
								</p>
							</div>
						</div>
						<div className="row faqs-box spno">
							<div className="col-md-8 col-lg-8 col-xl-8">
								<div className="faq-media" style={{ cursor: "pointer" }}>
									<SpecificationsGalleryBox />
								</div>
							</div>
							<SpecificationsBlog />
						</div>
					</section>
					{/* Section-2 End */}

					{/* Section-3 */}

					<section className="content-inner about-box" data-content="DE CE MLC" id="sidenav_aboutUs">
						<div className="about-bg"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-7 col-lg-6">
									<div className="section-head">
										<h2 className="title">DE CE SA ALEGI MLC RESIDENCE?</h2>
										<div className="dlab-separator bg-primary "></div>
										{/* <h4 className="mb-4">RESIDENTII NOSTRI </h4> */}
										<p>
											Atunci când caută o locuință oamenii au speranța că viața lor se va schimba în bine și vor găsi un
											motiv în plus pentru a fi fericiți. Ne-am convins că timpul petrecut în natură în compania celor
											dragi aduce bucurie și sănătate! De aceea, vom dezvolta complexul rezidențial MLC RESIDENCE -
											BRADU, într-o zonă cu acces rapid la artere rutiere importate, mijloacele de trasport în comun și
											cu numeroase facilități în apropiere: școli, grădinițe, magazine. Prin modul în care este
											poziționat proiectul vei putea avea parte de intimitate dar și avantajul de a ajunge în mai puțin
											de 10 minute până în centrul orasului .
										</p>
										<p>
											CONSULTANTA FINANCIARA GRATUITA Consultanții cu care colaborăm te ajută să găsești cele mai bune
											oferte bancare din piață deoarece contextul financiar este în continuă schimbare. Vei reduce
											semnificativ timpul necesar pentru pregătirea unei oferte personalizate de finanțare. Echipa
											noastră te va asista la întocmirea dosarului de credit, va prelua legătura cu banca, pe care a
											identificat-o împreună cu tine ca având cea mai bună ofertă și îți va oferi suport până la
											accesarea finală a creditului.
										</p>
									</div>
									<Link to={"/about-us"} className="btn btn-primary ">
										Despre noi
									</Link>
								</div>
								<div className="col-md-5 col-lg-6"></div>
							</div>
						</div>
					</section>
					{/* Section-3 End*/}
					{/* Section-4 Start */}
					{/* <section className="content-inner" data-content="MASTER PLAN" id="sidenav_masterPlan">
            <SitePlanTab />
          </section> */}
					{/* Section-4 End*/}
					{/* Section-5 Start*/}
					{/* <section id="sidenav_mainGallery">
            <div className="main-gallery">
              <div className="gallery-img">
                <img
                  src={proiect2019_1}
                  id="proiect-2019-1"
                  className="active"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
                <img src={proiect2019_2} id="proiect-2019-2" alt="" style={{ objectFit: "contain" }} />
                <img src={proiect2019_3} id="proiect-2019-3" alt="" style={{ objectFit: "contain" }} />
                <img
                  src={proiect2020_1}
                  id="proiect-2020-1"
                  className="active"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
                <img src={proiect2020_2} id="proiect-2020-2" alt="" style={{ objectFit: "contain" }} />
                <img src={proiect2020_3} id="proiect-2020-3" alt="" style={{ objectFit: "contain" }} />
                <img
                  src={proiect2021_1}
                  id="proiect-2021-1"
                  className="active"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
                <img src={proiect2021_2} id="proiect-2021-2" alt="" style={{ objectFit: "contain" }} />
                <img src={proiect2021_3} id="proiect-2021-3" alt="" style={{ objectFit: "contain" }} />
              </div>
              <div className="gallery-area">
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={proiect2019_1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Proiect 2019</h3>
                      <span>
                        <i className="las la-image"></i> 03
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="proiect-2019-1">
                      <div className="media">
                        <img src={proiect2019_1} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2019-2">
                      <div className="media">
                        <img src={proiect2019_2} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2019-3">
                      <div className="media">
                        <img src={proiect2019_3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={proiect2020_1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Proiect 2020</h3>
                      <span>
                        <i className="las la-image"></i> 03
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="proiect-2020-1">
                      <div className="media">
                        <img src={proiect2020_1} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2020-2">
                      <div className="media">
                        <img src={proiect2020_2} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2020-3">
                      <div className="media">
                        <img src={proiect2020_3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={proiect2021_1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Proiect 2021</h3>
                      <span>
                        <i className="las la-image"></i> 03
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="proiect-2021-1">
                      <div className="media">
                        <img src={proiect2021_1} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2021-2">
                      <div className="media">
                        <img src={proiect2021_2} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="proiect-2021-3">
                      <div className="media">
                        <img src={proiect2021_3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
					{/* Section-5 End*/}
					{/* Section-7 Start*/}
					{/* <section className="content-inner-2" data-content="NEWS & EVENT" id="sidenav_newsEvent">
            <EventSection />
          </section> */}
					{/* Section-7 End*/}

					{/* <Mapview /> */}
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}

export default Index1;
