import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "../../Layout/Header";
import Footer2 from "../../Layout/Footer2";
import PageTitle from "../../Layout/PageTitle";
import { VideoPopup2 } from "../../Element/VideoPopup";

import videoFundal from "./../../../images/2021/video_fundal.jpeg";

import gal1 from "./../../../images/2021/exterior3.jpeg";
import gal2 from "./../../../images/2021/exterior2.jpeg";
import gal3 from "./../../../images/2021/exterior1.jpg";

import g2021_1 from "./../../../images/2021/g2021-1.jpeg";
import g2021_2 from "./../../../images/2021/g2021-2.jpeg";
import g2021_3 from "./../../../images/2021/g2021-3.jpeg";
import g2021_4 from "./../../../images/2021/g2021-4.jpeg";
import g2021_5 from "./../../../images/2021/g2021-5.jpeg";
import g2021_6 from "./../../../images/2021/g2021-6.jpeg";
import g2021_7 from "./../../../images/2021/g2021-7.jpeg";
import g2021_8 from "./../../../images/2021/g2021-8.jpeg";
import g2021_9 from "./../../../images/2021/g2021-9.jpeg";

import plan1 from "./../../../images/2021/plan_parter.jpeg";
import plan2 from "./../../../images/2021/plan_etaj.jpeg";

import SwiperSlider from "../Aboutus/SwiperSlider";

//Light Gallery on icon click
const Iconimage = (props) => {
	const { openLightbox } = useLightbox();

	return (
		<Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="lightimg ">
			<i className="ti-zoom-in icon-bx-xs"></i>
		</Link>
	);
};

const detailBlog = [
	{ icon: <i className="ti ti-location-pin" />, title: "LOCATIE", subtitle: "Bradu" },
	{ icon: <i className="ti ti-ruler-alt-2" />, title: "MĂRIME PROIECT", subtitle: "5400 mp" },
];

const projectPlans = [{ image: plan1 }, { image: plan2 }];

const OverlayBlog = ({ imageType, index }) => {
	return (
		<>
			<div className="dlab-box gallery-box-2">
				<div className="dlab-media dlab-img-overlay1 dlab-img-effect">
					<img src={imageType} alt="" />
					<div className="overlay-bx">
						<Iconimage imageToOpen={index} />
					</div>
				</div>
			</div>
		</>
	);
};

class Project2021 extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white" id="lightgallery">
					{/*  banner  */}
					<PageTitle motherMenu="Proiect 2021" activeMenu="Proiect 2021" />
					{/*  Project Img  */}
					<SimpleReactLightbox>
						<SRLWrapper>
							{/* <div className="section-full content-inner-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mfp-gallery">
                      <OverlayBlog imageType={img1} />
                    </div>
                  </div>
                </div>
              </div> */}
							{/*  Project Img End */}
							{/* Project Info  */}

							<section className="section-full content-inner">
								<div className="container">
									<div className="row">
										<div className="col-lg-5 m-b30">
											<div className="section-head mb-3">
												<p>2020-2021</p>
												<h4 className="title">PROIECT REZIDENȚIAL DE 10 IMOBILE INDIVIDUALE</h4>
											</div>
											<p>
												Combinând creativitatea, experiența și atenția la detalii, proiectul MLC RESIDENCE - BRADU ,
												vine să îndeplinească toate cerințele tale atunci când vrei să bifezi sentimentul de ACASĂ.
												Într-o locație ideală, la numai 3km față de AUCHAN -BRADU , va punem la dispoziție 10 de case cu
												dotări și facilități moderne, un complex rezidențial intim, ce va construi o comunitate frumoasă
												unde siguranța și calitatea reprezintă elementele cheie după care am construit.
											</p>
											<p className="m-b30">
												Localizarea acestuia vine ca un punct cheie pentru stilul tău de viață activ, bifând astfel
												nivelul așteptărilor tale cu privire la locul îl care îl poți numi ACASĂ. Experiența anterioară
												ne-a condus către eficientizarea nevoilor tale și implementarea acestora în noul proiect.
												Compartimentarea eficientă și calitatea sunt elemente menite să asigure starea de bine denumită
												ACASĂ.
											</p>
											<p className="m-b20">
												Cu un design deosebit, vom avea locuințe cu suprafețe utile totale de 80 mp si respectiv 400 mp
												teren , terase generoase , compartimentate astfel încât să ofere un confort ridicat, care să
												bifeze absolut toate așteptările tale.
											</p>
											<p className="m-b20">
												<b>ARHITECTURA:</b> Am construit atent la detalii gândindu-ne cum ți-ai dori să îți petreci
												diminețile în grădină, cum să beneficiezi de o amenajare peisagistică spectaculoasă, ce fel de
												elemente de recreere ar fi mai la îndemână pentru tine. Și iată că punem accent pe creare de
												comunitate care să aprecieze lucrul bine făcut: securitate, siguranță și intimitate. Arhitectura
												proiectului nostru a fost concepută gândindu-ne la tine și la cum esti tu! Din acest motiv,
												elementele moderne povestesc mult despre nevoile tale și despre identitatea ta.
											</p>
											<p>
												Atunci când caută o locuință oamenii au speranța că viața lor se va schimba în bine și vor găsi
												un motiv în plus pentru a fi fericiți. Ne-am convins că timpul petrecut în natură în compania
												celor dragi aduce bucurie și sănătate! De aceea, vom dezvolta complexul rezidențial MLC
												RESIDENCE - BRADU, într-o zonă cu acces rapid la artere rutiere importate, mijloacele de
												trasport în comun și cu numeroase facilități în apropiere: școli, grădinițe, magazine. Prin
												modul în care este poziționat proiectul vei putea avea parte de intimitate dar și avantajul de a
												ajunge în mai puțin de 10 minute până în centrul orasului .
											</p>
											{/* <a href="https://www.youtube.com" className="popup-youtube m-r20 video btn btn-primary btn-video">
                        <i className="fa fa-play"></i>
                        <span></span>
                      </a> */}
											<Link to={"/contact"} className="btn btn-primary">
												Contact
											</Link>
										</div>
										<div className="col-lg-7">
											<div className="m-b30 mfp-gallery">
												<OverlayBlog imageType={gal1} index={0} />
											</div>
											<div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
												{detailBlog.map((data, index) => (
													<div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 p-lr0" key={index}>
														<div className="pro-details">
															{data.icon}
															<strong>{data.title}</strong> {data.subtitle}
														</div>
													</div>
												))}
											</div>
											<div className="mt-5 pt-4 mfp-gallery">
												<OverlayBlog imageType={gal3} index={1} />
											</div>
										</div>
									</div>
								</div>
								{/* Project Info  End*/}
								{/*  Project Video Section */}
								<section
									className="m-t100 section-full content-inner-2 overlay-black-middle video-bx"
									style={{
										backgroundImage: "url(" + videoFundal + ")",
										backgroundSize: "cover",
										objectFit: "contain",
										// objectPosition: "60% top",
									}}
								>
									<div className="container">
										<div className="row">
											<div className="col-lg-12 text-center text-white">
												<div className="video-play">
													<VideoPopup2 id={"VTw8MGXeMZg"} />
												</div>
												<h2 className="video-title text-white">Pentru că meriți !</h2>
												<p className="video-content">
													Schimbă-ți apartamentul cu o casă de vis la MLC RESIDENCE Bradu !<br />
													Vino să faci parte dintr-o comunitate unde calitatea vieții este la superlativ!
													<br />
													Aer curat, liniște și siguranță !
												</p>
												{/* <Link to={"/contact"} className="btn btn-primary radius-xl">
                          <span className="text-black">Contact</span>
                        </Link> */}
											</div>
										</div>
									</div>
								</section>
								{/*  Project Video Section End */}
								{/* Our Gallery  */}
								{/* Our Gallery End */}
							</section>

							<section className="content-inner-1" data-content="GALERIE">
								<div className="container">
									<div className="section-head text-center">
										<h2 className="title">Galerie</h2>
										<div className="dlab-separator bg-primary"></div>
									</div>
									<div className="row our-gallery mfp-gallery">
										<GallerySection />
									</div>
								</div>
							</section>
						</SRLWrapper>
					</SimpleReactLightbox>

					<section className="content-inner-1" data-content="PLAN PROIECT" id="masterPlan">
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title">Plan proiect</h2>
								<div className="dlab-separator bg-primary"></div>
								<p>
									Cu finisaje premium și spații larg deschise, fiecare plan de vă aduce un lux de neegalat fără a
									sacrifica confortul.
									<br /> Planurile reprezintă redarea arhitectilor si designerilor. Toate dimensiunile sunt aproximative
								</p>
							</div>
						</div>
						<SwiperSlider galleryBlog={projectPlans} />
					</section>
					{/* Content Area End  */}
				</div>
				<Footer2 />
			</Fragment>
		);
	}
}

function GallerySection() {
	return (
		<>
			{/* <div className="col-lg-12 col-md-12 col-sm-12 m-b30">
        <OverlayBlog imageType={pic2} />
      </div> */}
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={gal2} index={2} />
			</div>

			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_8} index={3} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_7} index={4} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_9} index={5} />
			</div>

			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_1} index={6} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_2} index={7} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_3} index={8} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_5} index={9} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_6} index={10} />
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 m-b30">
				<OverlayBlog imageType={g2021_4} index={11} />
			</div>

			<div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan1} />
			</div>

			<div className="d-none col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
				<OverlayBlog imageType={plan2} />
			</div>
			{/* <div className="col-lg-6 col-md-6 col-sm-6 m-sm-b30 ">
        <OverlayBlog imageType={gal3} />
      </div> */}
		</>
	);
}
export { GallerySection };

export default Project2021;
